import actions from './actions';

const initState = { idToken: null };

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      console.log("success");
      return {
        idToken: 'success',
      };
    case actions.LOGIN_ERROR:
      console.log("error");
      return {
        idToken: null,
      };  
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
