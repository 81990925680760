const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGOUT: 'LOGOUT',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),

  loginSuccess: (token = false) => ({
    type: actions.LOGIN_SUCCESS,
    payload: { token },
  }),
  loginError: () => ({
    type: actions.LOGIN_ERROR,
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
};
export default actions;
