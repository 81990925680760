import scrumBoardActions from './actions';

const initialState = {
  boards: {},
  searchText: '',
  envLaunched: {},
  statusDetails: {},
  projectStatusDetails: {},
  vsCodeStatusDetails:{},
  proxyStatusDetails:{},
  ttydStatusDetails: {},
  dockerStatusDetails: {}
};
export default function scrumBoardReducer(state = initialState, action) {
  switch (action.type) {
    case scrumBoardActions.SET_BOARDS_DATA:
      return { ...state, ...action.payload };

    case scrumBoardActions.SET_CURRENT_BOARD_DATA:
      return { ...state, ...action.payload };

    case scrumBoardActions.SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload,
      };

    case scrumBoardActions.CLEAR_STATES:
      return {
        ...state,
        boards: {
          ...state.boards,
          [action.payload]: null
        },
        envLaunched: {
          ...state.envLaunched,
          [action.payload]: null,
        },
        statusDetails: {
          ...state.statusDetails,
          [action.payload]: null,
        },
        ttydStatusDetails: {
          ...state.ttydStatusDetails,
          [action.payload]: null,
        },
        dockerStatusDetails: {
          ...state.dockerStatusDetails,
          [action.payload]: null,
        },
        projectStatusDetails: {
          ...state.projectStatusDetails,
          [action.payload.id]: null,
        },
        proxyStatusDetails: {
          ...state.proxyStatusDetails,
          [action.payload]: null,
        },
        vsCodeStatusDetails: {
          ...state.vsCodeStatusDetails,
          [action.payload]: null,
        },
      }  
    case scrumBoardActions.CREATE_OR_UPDATE_BOARD:
      return {
        ...state,
        boards: action.payload,
      };

    case scrumBoardActions.SET_ENV_LAUNCHED:
      return {
        ...state,
        envLaunched: {
          ...state.envLaunched,
          [action.payload.id]: action.payload.envLaunched,
        },
      };    

    case scrumBoardActions.SET_STATUS_DETAILS:
      return {
        ...state,
        statusDetails: {
          ...state.statusDetails,
          [action.payload.id]: action.payload.statusDetails,
        },
    };      
    case scrumBoardActions.SET_TTYD_STATUS_DETAILS:
      return {
        ...state,
        ttydStatusDetails: {
          ...state.ttydStatusDetails,
          [action.payload.id]: action.payload.ttydStatusDetails,
        },
      };    
    case scrumBoardActions.SET_DOCKER_STATUS_DETAILS:
      return {
        ...state,
        dockerStatusDetails: {
          ...state.dockerStatusDetails,
          [action.payload.id]: action.payload.dockerStatusDetails,
        },
      };          
    case scrumBoardActions.EDIT_BOARD:
      return {
        ...state,
        boards: {
          ...state.boards,
          [action.payload.id]: {
            ...action.payload,
            editing: true,
          },
        },
      };
    case scrumBoardActions.DELETE_BOARD:
      return {
        ...state,
        boards: action.payload,
      };
    case scrumBoardActions.SET_PROJECT_STATUS_DETAILS:
      return {
        ...state,
        projectStatusDetails: {
          ...state.projectStatusDetails,
          [action.payload.id]: action.payload.projectStatusDetails,
        },
      };  
    case scrumBoardActions.SET_VSCODE_STATUS_DETAILS:
      return {
        ...state,
        vsCodeStatusDetails: {
          ...state.vsCodeStatusDetails,
          [action.payload.id]: action.payload.vsCodeStatusDetails,
        },
      };    
    case scrumBoardActions.SET_PROXY_STATUS_DETAILS:
      return {
        ...state,
        proxyStatusDetails: {
          ...state.proxyStatusDetails,
          [action.payload.id]: action.payload.proxyStatusDetails,
        },
      };    

      default:
      return state;
  }
}
