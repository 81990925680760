// saga.js
import { all, takeEvery, put, select, call, delay, race, take } from 'redux-saga/effects';
import scrumBoardActions from './actions';
import { loadState, saveState } from '@iso/lib/helpers/localStorage';
import {auth} from "../../library/firebase/firebase";

const getScrumBoards = state => state.scrumBoard;

async function getProjectList() {

  let resp;
  try {
       let userEmail = auth.currentUser.email;
       let response = await fetch('https://api.gitfire.io/codeflyer/projects?userId='+userEmail+'&&sort=(name:DESC)&&page=(1:10)');
       resp = await response.json() ;
       console.log(resp);
  }
  catch (e) {
      console.log(e.message);
      resp = { err: e.message };
  }

  return resp;
}

function* boardsRenderEffectSaga() {
  let boards;
  //saveState('scrum_boards', { boards });
  const resp = yield call(getProjectList);
  console.log(resp.projects);
  boards = resp.projects;
  yield put(scrumBoardActions.setBoardsData({ boards }));
}

async function updateIDBackend(myId) {

  let idIpMapping = {
    id : myId,
    inUse : true
  }

  let resp;
  try {
       resp = await fetch('https://api.gitfire.io/codeflyer/idIpMapping/'+myId, {
        method: 'PUT',
        body: JSON.stringify(idIpMapping),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
  }
  catch (e) {
      resp = { err: e.message };
  }
  return resp;
}

async function createProjectBackend(board) {

  let project = {
    id : board.id,
    userId : board.email,
    name : board.name,
    gitRepo : board.gitRepo,
    gitBranch : board.gitBranch,
  }

  let resp;
  try {
       resp = await fetch('https://api.gitfire.io/codeflyer/projects', {
        method: 'POST',
        body: JSON.stringify(project),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
  }
  catch (e) {
      console.log(e.message);
      resp = { err: e.message };
  }

  return resp;
}

async function launchVMBackend(board) {

  let resp;
  try {
       resp = await fetch('https://api.gitfire.io/codeflyer/projects/launchvm/'+board.id, {
        method: 'POST',
        body: JSON.stringify(""),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
  }
  catch (e) {
      console.log(e.message);
      resp = { err: e.message };
  }

  return resp;
}

async function launchIpv6Backend(board) {

  let resp;
  try {
       resp = await fetch('https://api.gitfire.io/codeflyer/projects/enableIpv6/'+board.id, {
        method: 'POST',
        body: JSON.stringify(""),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
  }
  catch (e) {
      console.log(e.message);
      resp = { err: e.message };
  }

  return resp;
}

async function pullGitBackend(board) {

  let resp;
  try {
       resp = await fetch('https://api.gitfire.io/codeflyer/projects/pullProjectRepo/'+board.id, {
        method: 'POST',
        body: JSON.stringify(""),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
  }
  catch (e) {
      console.log(e.message);
      resp = { err: e.message };
  }

  return resp;
}

function* updateId(action) {

  try {
    const resp = yield call(updateIDBackend, action.payload.id);

     if (resp != null) {
      let currentStatus = 'Project ID obtained';
      yield put(scrumBoardActions.setStatusDetails({id:action.payload.id, statusDetails: currentStatus}));
      let statusDetailsInfo = {
        id: action.payload.id,
        statusDetails: currentStatus
     }

      yield call(updateStatusDetailsBackend, statusDetailsInfo);
      yield put(scrumBoardActions.createProject(action.payload));
     }
  } catch (e) {
    console.log(e);
  }
}

async function preflightCheckBackend(email) {

  let resp;
  try {
    console.log("email"+email);
    let response = await fetch('https://api.gitfire.io/codeflyer/projects/preflightCheck/'+email)
    resp = await response.json() ;
  }
  catch (e) {
      resp = { err: e.message };
  }
  return resp;
}

function* preflightCheck(action) {

  try {
    const resp = yield call(preflightCheckBackend, action.payload.email);
    let completedId = 'PROJECT_PREFLIGHT_COMPLETE'+ action.payload.id;

     if (resp != null) {
        console.log("resp status"+resp.status);
        console.log("Num active"+resp.numActive);

        if (resp.status === 'Success') {
          console.log("Num active"+resp.numActive);
          yield put({type: completedId});
        }
        if (resp.status === 'Failed') {
          yield put(scrumBoardActions.setStatusDetails({id:action.payload.id, statusDetails: 'Limit Exceeded of 2 Launches. Delete other projects'}));
        }
     }
  } catch (e) {
    yield put(scrumBoardActions.setStatusDetails({id:action.payload.id, statusDetails: 'Limit Exceeded of 2 Launches. Delete other projects'}));
    console.log(e);
  }
}

async function getProjectStatus(id) {

  let resp;
  try {
       let response = await fetch('https://api.gitfire.io/codeflyer/projects/'+id)
       resp = await response.json() ;
       console.log(resp);
  }
  catch (e) {
      console.log(e.message);
      resp = { err: e.message };
  }

  return resp;
}

function* backgroundVmPoller(id) {
  let cancelId = 'CANCEL_VM_POLL'+ id;
  let completedId = 'VM_LAUNCH_COMPLETE'+id;
  while (true) { 
     const resp = yield call(getProjectStatus, id);
     if (resp.project.status==='VMLaunched') {
        let currentStatus = 'VM Launched';
        yield put(scrumBoardActions.setStatusDetails({id:id, statusDetails: currentStatus}));
        let statusDetailsInfo = {
          id: id,
          statusDetails: currentStatus
       }

        yield call(updateStatusDetailsBackend, statusDetailsInfo);
        yield put({type: completedId});
        yield put({type: cancelId});
     } else {
        yield delay(5000);
     }
   }
}

function* backgroundV6Poller(id) {
  let cancelId = 'CANCEL_V6_POLL'+ id;
  let completedId = 'V6_SETUP_COMPLETE'+id;
  while (true) { 
     const resp = yield call(getProjectStatus, id);
     console.log(resp.project.status);
     if (resp.project.status==='EnvironmentAttached') {
        let currentStatus = 'Network Setup complete';
        yield put(scrumBoardActions.setStatusDetails({id:id, statusDetails: currentStatus}));

        let statusDetailsInfo = {
          id: id,
          statusDetails: currentStatus
       }

        yield call(updateStatusDetailsBackend, statusDetailsInfo);
        yield put({type: completedId});
        yield put({type: cancelId});
     } else {
        yield delay(5000);
     }
   }
}

function* backgroundGitPoller(id) {
  let cancelId = 'CANCEL_GIT_POLL'+ id;
  let completedId = 'GIT_SETUP_COMPLETE'+id;
  while (true) { 
     const resp = yield call(getProjectStatus, id);
     if (resp.project.status==='GitRepoDownloaded') {
        yield put(scrumBoardActions.setEnvLaunched({id:id, envLaunched: true}));
        let currentStatus = 'Git repo download complete';
        yield put(scrumBoardActions.setStatusDetails({id:id, statusDetails: currentStatus}));

        let statusDetailsInfo = {
          id: id,
          envLaunched: true,
          statusDetails: currentStatus
       }

        yield call(updateStatusDetailsBackend, statusDetailsInfo);

        yield put({type: completedId});
        yield put({type: cancelId});
     } else {
        yield delay(5000);
     }
   }
}

function* watchVmPollerTask(action) {
    let cancelId = 'CANCEL_VM_POLL'+ action.payload;
    yield put(scrumBoardActions.setStatusDetails({id:action.payload, statusDetails: 'Waiting for VM to launch'}));
    yield race({
      task: call(backgroundVmPoller, action.payload),
      cancel: take(cancelId)
    })
}

function* watchV6PollerTask(action) {
  let cancelId = 'CANCEL_V6_POLL'+ action.payload;
  yield put(scrumBoardActions.setStatusDetails({id:action.payload, statusDetails: 'Setting up networking'}));
  yield race({
    task: call(backgroundV6Poller, action.payload),
    cancel: take(cancelId)
  })
}

function* watchGitPollerTask(action) {
  let cancelId = 'CANCEL_GIT_POLL'+ action.payload;
  yield put(scrumBoardActions.setStatusDetails({id:action.payload, statusDetails: 'Pulling Git Repo'}));
  yield race({
    task: call(backgroundGitPoller, action.payload),
    cancel: take(cancelId)
  })
}

function* createProject(action) {
  let boards = action.payload.boards;

  try {
    const resp = yield call(createProjectBackend, boards[action.payload.id]);

     if (resp != null) {
      let scrum_boards = {
        boards: boards,
      };
      //saveState('scrum_boards', scrum_boards);
      yield put(scrumBoardActions.setStatusDetails({id:action.payload.id, statusDetails: 'Project Created'}));
      yield put(scrumBoardActions.launchVM(action.payload));
      yield put(scrumBoardActions.startVMPoller(action.payload.id));
      yield take('VM_LAUNCH_COMPLETE'+action.payload.id);
      yield put(scrumBoardActions.launchIpv6(action.payload));
      yield put(scrumBoardActions.startV6Poller(action.payload.id));
      yield take('V6_SETUP_COMPLETE'+action.payload.id);
      yield put(scrumBoardActions.pullGit(action.payload));
      yield put(scrumBoardActions.startGitPoller(action.payload.id));
      yield take('GIT_SETUP_COMPLETE'+action.payload.id);
      yield put(scrumBoardActions.createOrUpdateBoard(scrum_boards));
      //saveState('scrum_boards', scrum_boards);
     }
  } catch (e) {
    console.log(e);
  }
}

function* launchVM(action) {
  let boards = action.payload.boards;
  let board = boards[action.payload.id];

  try {
    const resp = yield call(launchVMBackend, board);
  } catch (e) {
    console.log(e);
  }
}

function* launchIpv6(action) {
  let boards = action.payload.boards;
  let board = boards[action.payload.id];

  try {
    const resp = yield call(launchIpv6Backend, board);
  } catch (e) {
    console.log(e);
  }
}

function* pullGit(action) {
  let boards = action.payload.boards;
  let board = boards[action.payload.id];

  try {
    const resp = yield call(pullGitBackend, board);
  } catch (e) {
    console.log(e);
  }
}


function* createOrUpdateBoardEffectSaga(action) {
  let scrum_boards = yield select(getScrumBoards);
  const boards = {
    ...scrum_boards.boards,
    [action.payload.id]: { ...action.payload, editing: false, statusDetails: 'Creating Project'}
  };

  let project_info = {
     boards: boards,
     id: action.payload.id,
     email: action.payload.email
  }

  yield put(scrumBoardActions.projectPreflight(project_info));
  yield take('PROJECT_PREFLIGHT_COMPLETE'+action.payload.id);
  yield put(scrumBoardActions.updateId(project_info));
}

function* deleteBoardEffectSaga(action) {
  let scrum_boards = yield select(getScrumBoards);
  const BOARDS = {
    ...scrum_boards.boards,
  };
  delete BOARDS[action.payload];

  const boards = BOARDS;

  scrum_boards = {
    ...scrum_boards,
    boards,
  };
  //saveState('scrum_boards', scrum_boards);
  yield put(scrumBoardActions.deleteProject(action.payload))
  yield take('PROJECT_DELETE_COMPLETE'+action.payload);
  yield put(scrumBoardActions.deleteBoard(boards));
  yield put(scrumBoardActions.setEnvLaunched({id:action.payload, envLaunched: false}));
  yield put(scrumBoardActions.setStatusDetails({id:action.payload, statusDetails: null}));
  yield put(scrumBoardActions.setProjectStatusDetails({id:action.payload, projectStatusDetails: null}));
  yield put(scrumBoardActions.setVSCodeStatusDetails({id:action.payload, vsCodeStatusDetails: null}));
  yield put(scrumBoardActions.setProxyStatusDetails({id:action.payload, proxyStatusDetails: null}));
  yield put(scrumBoardActions.setTtydStatusDetails({id:action.payload, ttydStatusDetails: null}));
  yield put(scrumBoardActions.setDockerStatusDetails({id:action.payload, dockerStatusDetails: null}));
  yield put(scrumBoardActions.s)
}

async function launchComposeBackend(board) {

  let resp;
  try {
       resp = await fetch('https://api.gitfire.io/codeflyer/projects/launchProject/'+board.id, {
        method: 'POST',
        body: JSON.stringify(""),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
  }
  catch (e) {
      console.log(e.message);
      resp = { err: e.message };
  }

  return resp;
}

async function updateProjectInfoBackend(board) {
  let projectInfo = {
    id : board.id,
    composeYaml : board.composeYaml,
    servicePort: board.servicePort
  }

  console.log('Board composeYaml' + board.composeYaml);
  console.log('Board port' + board.servicePort);

  let resp;
  try {
       resp = await fetch('https://api.gitfire.io/codeflyer/projects/'+board.id, {
        method: 'PUT',
        body: JSON.stringify(projectInfo),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
  }
  catch (e) {
      resp = { err: e.message };
  }
  return resp;
}

async function updateStatusDetailsBackend(statusDetails) {

  let resp;
  try {
       resp = await fetch('https://api.gitfire.io/codeflyer/projects/setStatusDetails/', {
        method: 'POST',
        body: JSON.stringify(statusDetails),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
  }
  catch (e) {
      resp = { err: e.message };
  }
  return resp;
}

function* updateProjectInfo(action) {
  try {
    const resp = yield call(updateProjectInfoBackend, action.payload);

     if (resp != null) {
      yield put(scrumBoardActions.setProjectStatusDetails({id:action.payload.id, projectStatusDetails: 'Project Updated'}));
      let completedId = 'PROJECT_UPDATE_COMPLETE'+action.payload.id;
      yield put({type: completedId});
     }
  } catch (e) {
    console.log(e);
  }
}


async function launchBuildBackend(board) {

  let resp;
  try {
       resp = await fetch('https://api.gitfire.io/codeflyer/projects/buildProject/'+board.id, {
        method: 'POST',
        body: JSON.stringify(""),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
  }
  catch (e) {
      console.log(e.message);
      resp = { err: e.message };
  }

  return resp;
}

async function getProjectBuildStatus(id) {

  let resp;
  try {
       let response = await fetch('https://api.gitfire.io/codeflyer/projects/buildStatus/'+id)
       resp = await response.json() ;
       console.log(resp);
  }
  catch (e) {
      console.log(e.message);
      resp = { err: e.message };
  }

  return resp;
}

async function getProjectLaunchStatus(id) {

  let resp;
  try {
       let response = await fetch('https://api.gitfire.io/codeflyer/projects/projectStatus/'+id)
       resp = await response.json() ;
       console.log(resp);
  }
  catch (e) {
      console.log(e.message);
      resp = { err: e.message };
  }

  return resp;
}


function* backgroundComposePoller(board) {
  let cancelId = 'CANCEL_COMPOSE_POLL'+ board.id;
  let completedId = 'PROJECT_LAUNCH_COMPLETE'+board.id;
  while (true) { 
     const resp = yield call(getProjectLaunchStatus, board.id);
     if (resp.status==='running' && resp.numServices !== 0) {
        let currentStatus = 'Project Launched';
        yield put(scrumBoardActions.setProjectStatusDetails({id:board.id, projectStatusDetails: currentStatus}));
        let statusDetailsInfo = {
          id: board.id,
          projectStatusDetails: currentStatus
        }
        yield call(updateStatusDetailsBackend, statusDetailsInfo);

        yield put({type: completedId});
        yield put({type: cancelId});
     } else {
        yield delay(5000);
     }
   }
}

function* launchComposePoller(action) {
    let cancelId = 'CANCEL_COMPOSE_POLL'+ action.payload.id;
    let currentStatus = 'Waiting for Project to launch';
    yield put(scrumBoardActions.setProjectStatusDetails({id:action.payload.id, projectStatusDetails: currentStatus}));
    let statusDetailsInfo = {
      id: action.payload.id,
      projectStatusDetails: currentStatus
    }
    yield call(updateStatusDetailsBackend, statusDetailsInfo);

    yield race({
      task: call(backgroundComposePoller, action.payload),
      cancel: take(cancelId)
    })
}

function* launchCompose(action) {
    let board = action.payload;

    try {
      const resp = yield call(launchComposeBackend, board);
    } catch (e) {
      console.log(e);
    }
  }

  async function getLogsStatus(id) {

    let resp;
    try {
         let response = await fetch('https://api.gitfire.io/codeflyer/projects/logs?id='+id+"&&sinceSeconds="+30);
         resp = await response.json() ;
         console.log(resp);
    }
    catch (e) {
        console.log(e.message);
        resp = { err: e.message };
    }
  
    return resp;
  }  

  function* backgroundLogsPoller(board) {
    let cancelId = 'CANCEL_LOGS_POLL'+ board.id;
    let completedId = 'LOG_POLL_COMPLETE'+ board.id;

    while (true) { 
        const resp = yield call(getLogsStatus, board.id);
        if (resp.logs !=null && resp.logs.length > 0) {
          yield put({type: completedId});
          yield put({type: cancelId});
        } else {
          yield delay(5000);
        }
      }
  }

  function* launchLogsPoller(action) {
    let cancelId = 'CANCEL_LOGS_POLL'+ action.payload.id;
    yield race({
      task: call(backgroundLogsPoller, action.payload),
      cancel: take(cancelId)
    })
  }

  async function getDockerStatus(id) {

    let resp;
    try {
         let response = await fetch('https://api.gitfire.io/codeflyer/projects/dockerStatus/'+id);
         resp = await response.json() ;
         console.log(resp);
    }
    catch (e) {
        console.log(e.message);
        resp = { err: e.message };
    }
  
    return resp;
  }  

  function* backgroundDockerPoller(board) {

    while (true) { 
        const resp = yield call(getDockerStatus, board.id);
        if (resp.dockerStatusList != null && resp.dockerStatusList.length > 0) {
          yield put(scrumBoardActions.setDockerStatusDetails({id:board.id, dockerStatusDetails: resp}));
          

          let statusDetailsInfo = {
            id: board.id,
            dockerStatusList: resp.dockerStatusList
          }
          yield call(updateStatusDetailsBackend, statusDetailsInfo);

          yield delay(3000);
        } else {
          yield delay(30000);
        }
      }
  }

  function* launchDockerPoller(action) {
    let cancelId = 'CANCEL_DOCKER_POLL'+ action.payload.id;
    yield race({
      task: call(backgroundDockerPoller, action.payload),
      cancel: take(cancelId)
    })
  }

  async function startLogsTailer(id) {
    let resp;
    try {
         resp = await fetch('https://api.gitfire.io/codeflyer/projects/projectLogTailer/'+id, {
          method: 'POST',
          body: JSON.stringify(""),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        })
    }
    catch (e) {
        console.log(e.message);
        resp = { err: e.message };
    }
    return resp;
  } 

  function* launchLogsTailer(action) {
    const resp = yield call(startLogsTailer, action.payload.id);
  }

  async function getTtydStatus(id, port) {

    let resp;
    try {
         let response = await fetch('https://api.gitfire.io/codeflyer/projects/ttydStatus?id='+id+"&&port="+port);
         resp = await response.json() ;
         console.log(resp);
    }
    catch (e) {
        console.log(e.message);
        resp = { err: e.message };
    }
  
    return resp;
  }  

function* backgroundTtydPoller(portInfo) {
  let cancelId = 'CANCEL_TTYD_POLL'+ portInfo.board.id;
  while (true) { 
      const resp = yield call(getTtydStatus, portInfo.board.id, portInfo.port);
      if (resp.status==='running') {
        yield delay(5000);
        let currentStatus = 'running';
        yield put(scrumBoardActions.setTtydStatusDetails({id:portInfo.board.id, ttydStatusDetails: currentStatus}));
        let statusDetailsInfo = {
          id: portInfo.board.id,
          ttydStatusDetails: currentStatus
        }
        yield call(updateStatusDetailsBackend, statusDetailsInfo);

        yield put({type: cancelId});
      } else {
        yield delay(5000);
      }
    }
}
  
function* launchTtydPoller(action) {
  let cancelId = 'CANCEL_TTYD_POLL'+ action.payload.board.id;
  let currentStatus = 'not running';
  yield put(scrumBoardActions.setTtydStatusDetails({id:action.payload.board.id, ttydStatusDetails: currentStatus}));
  let statusDetailsInfo = {
    id: action.payload.board.id,
    ttydStatusDetails: currentStatus
  }
  yield call(updateStatusDetailsBackend, statusDetailsInfo);

  yield race({
    task: call(backgroundTtydPoller, action.payload),
    cancel: take(cancelId)
  })
}
    
function* launchProject(action) {
  let board = action.payload;

  try {
      yield put(scrumBoardActions.updateProject(board));
      yield put(scrumBoardActions.setProjectStatusDetails({id:board.id, projectStatusDetails: 'Project Updated'}));
      yield take('PROJECT_UPDATE_COMPLETE'+board.id);
      yield put(scrumBoardActions.launchCompose(board));
      yield put(scrumBoardActions.launchComposePoller(board));
      let portInfo = {
        board: board,
        port: 7681
      }
      yield put(scrumBoardActions.launchTtydPoller(portInfo));
      yield put(scrumBoardActions.launchLogsPoller(board));
      yield take('LOG_POLL_COMPLETE'+board.id);
      yield put(scrumBoardActions.launchLogsTailer(board));
      yield put(scrumBoardActions.launchDockerPoller(board));
  } catch (e) {
    console.log(e);
  }
}

async function stopComposeBackend(board) {
  let resp;
  try {
       resp = await fetch('https://api.gitfire.io/codeflyer/projects/stopProject/'+board.id, {
        method: 'POST',
        body: JSON.stringify(""),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
  }
  catch (e) {
      console.log(e.message);
      resp = { err: e.message };
  }
  return resp;
}

function* backgroundStopComposePoller(board) {
  let cancelId = 'CANCEL_STOP_COMPOSE_POLL'+ board.id;
  let completedId = 'PROJECT_STOP_COMPLETE'+board.id;
  while (true) { 
     const resp = yield call(getProjectLaunchStatus, board.id);
     if (resp.status==='Not Running') {
        let currentStatus = 'Project Stopped';
        yield put(scrumBoardActions.setProjectStatusDetails({id:board.id, projectStatusDetails: currentStatus}));
        let statusDetailsInfo = {
          id: board.id,
          projectStatusDetails: currentStatus
        }
        yield call(updateStatusDetailsBackend, statusDetailsInfo);
        yield put({type: completedId});
        yield put({type: cancelId});
     } else {
        yield delay(5000);
     }
   }
}

function* stopComposePoller(action) {
  let cancelId = 'CANCEL_STOP_COMPOSE_POLL'+ action.payload.id;
  let currentStatus = 'Waiting for Project to Stop';
  yield put(scrumBoardActions.setProjectStatusDetails({id:action.payload.id, projectStatusDetails: currentStatus}));
  let statusDetailsInfo = {
    id: action.payload.id,
    projectStatusDetails: currentStatus
  }
  yield call(updateStatusDetailsBackend, statusDetailsInfo);

  yield race({
    task: call(backgroundStopComposePoller, action.payload),
    cancel: take(cancelId)
  })
}

function* stopCompose(action) {
  let board = action.payload;

  try {
    const resp = yield call(stopComposeBackend, board);
  } catch (e) {
    console.log(e);
  }
}

function* stopProject(action) {
  let board = action.payload;

  try {
      yield put(scrumBoardActions.stopCompose(board));
      yield put(scrumBoardActions.stopComposePoller(board));
  } catch (e) {
    console.log(e);
  }
}

async function deleteProjectBackend(id) {
  let resp;
  try {
       resp = await fetch('https://api.gitfire.io/codeflyer/projects/'+id, {
        method: 'DELETE',
      })
      resp = await fetch('https://api.gitfire.io/codeflyer/projects/deleteStatusDetails/'+id, {
        method: 'DELETE',
      })
  }
  catch (e) {
      console.log(e.message);
      resp = { err: e.message };
  }
  return resp;
}

function* deleteProject(action) {

  try {
      const resp = yield call(deleteProjectBackend, action.payload);
  } catch (e) {
    console.log(e);
  }
}

async function launchVSCodeBackend(board) {
  let resp;
  try {
       resp = await fetch('https://api.gitfire.io/codeflyer/projects/launchVsCode/'+board.id, {
        method: 'POST',
        body: JSON.stringify(""),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
  }
  catch (e) {
      console.log(e.message);
      resp = { err: e.message };
  }
  return resp;
}

async function getVSCodeLaunchStatus(id) {

  let resp;
  try {
       let response = await fetch('https://api.gitfire.io/codeflyer/projects/vscodeStatus/'+id)
       resp = await response.json() ;
       console.log(resp);
  }
  catch (e) {
      console.log(e.message);
      resp = { err: e.message };
  }

  return resp;
}

function* backgroundVSCodePoller(board) {
  let cancelId = 'CANCEL_VSCODE_POLL'+ board.id;
  let completedId = 'PROJECT_STOP_COMPLETE'+board.id;
  while (true) { 
     const resp = yield call(getVSCodeLaunchStatus, board.id);
     if (resp.status==='running') {
        let currentStatus = 'VSCode Launched';
        yield put(scrumBoardActions.setVSCodeStatusDetails({id:board.id, vsCodeStatusDetails: currentStatus}));
        let statusDetailsInfo = {
          id: board.id,
          vsCodeStatusDetails: currentStatus
        }
        yield call(updateStatusDetailsBackend, statusDetailsInfo);

        yield put({type: completedId});
        yield put({type: cancelId});
     } else {
        yield delay(5000);
     }
   }
}

function* launchVSCodePoller(action) {
  let cancelId = 'CANCEL_VSCODE_POLL'+ action.payload.id;
  let currentStatus = 'Waiting for VSCode to Start';
  yield put(scrumBoardActions.setVSCodeStatusDetails({id:action.payload.id, vsCodeStatusDetails: currentStatus}));
  let statusDetailsInfo = {
    id: action.payload.id,
    vsCodeStatusDetails: currentStatus
  }
  yield call(updateStatusDetailsBackend, statusDetailsInfo);

  yield race({
    task: call(backgroundVSCodePoller, action.payload),
    cancel: take(cancelId)
  })
}

function* launchVSCode(action) {
  let board = action.payload;

  try {
    const resp = yield call(launchVSCodeBackend, board);
  } catch (e) {
    console.log(e);
  }
}

function* launchVSCodeProject(action) {
  let board = action.payload;

  try {
      yield put(scrumBoardActions.launchVSCode(board));
      yield put(scrumBoardActions.launchVSCodePoller(board));
  } catch (e) {
    console.log(e);
  }
}

async function launchProxyBackend(board) {
  let resp;
  try {
       resp = await fetch('https://api.gitfire.io/codeflyer/projects/launchProxy/'+board.id, {
        method: 'POST',
        body: JSON.stringify(""),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
  }
  catch (e) {
      console.log(e.message);
      resp = { err: e.message };
  }
  return resp;
}

async function getProxyLaunchStatus(id) {

  let resp;
  try {
       let response = await fetch('https://api.gitfire.io/codeflyer/projects/proxyStatus/'+id)
       resp = await response.json() ;
       console.log(resp);
  }
  catch (e) {
      console.log(e.message);
      resp = { err: e.message };
  }

  return resp;
}

function* backgroundProxyPoller(board) {
  let cancelId = 'CANCEL_PROXY_POLL'+ board.id;
  let completedId = 'PROXY_STOP_COMPLETE'+board.id;
  while (true) { 
     const resp = yield call(getProxyLaunchStatus, board.id);
     if (resp.status==='active' && resp.statusDetail==='running') {
        let currentStatus = 'Proxy Launched';
        yield put(scrumBoardActions.setProxyStatusDetails({id:board.id, proxyStatusDetails: currentStatus}));
        let statusDetailsInfo = {
          id: board.id,
          proxyStatusDetails: currentStatus
        }
        yield call(updateStatusDetailsBackend, statusDetailsInfo);

        yield put({type: completedId});
        yield put({type: cancelId});
     } else {
        yield delay(5000);
     }
   }
}

function* launchProxyPoller(action) {
  let cancelId = 'CANCEL_PROXY_POLL'+ action.payload.id;
  let currentStatus = 'Waiting for Proxy to Start';
  yield put(scrumBoardActions.setProxyStatusDetails({id:action.payload.id, proxyStatusDetails: currentStatus}));
  let statusDetailsInfo = {
    id: action.payload.id,
    proxyStatusDetails: currentStatus
  }
  yield call(updateStatusDetailsBackend, statusDetailsInfo);

  yield race({
    task: call(backgroundProxyPoller, action.payload),
    cancel: take(cancelId)
  })
}

function* launchProxy(action) {
  let board = action.payload;

  try {
    const resp = yield call(launchProxyBackend, board);
  } catch (e) {
    console.log(e);
  }
}

function* launchProxyProject(action) {
  let board = action.payload;

  try {
      yield put(scrumBoardActions.launchProxy(board));
      yield put(scrumBoardActions.launchProxyPoller(board));
  } catch (e) {
    console.log(e);
  }
}


export default function* scrumBoardSaga() {
  yield all([
    takeEvery(scrumBoardActions.LOAD_BOARDS_DATA_SAGA, boardsRenderEffectSaga),
    takeEvery(
      scrumBoardActions.CREATE_OR_UPDATE_BOARD_WATCHER,
      createOrUpdateBoardEffectSaga
    ),
    takeEvery(
      scrumBoardActions.UPDATE_ID,
      updateId
    ),
    takeEvery(
      scrumBoardActions.PREFLIGHT,
      preflightCheck
    ),
    takeEvery(
      scrumBoardActions.CREATE_PROJECT,
      createProject
    ),
    takeEvery(
      scrumBoardActions.LAUNCH_VM,
      launchVM
    ),
    takeEvery(
      scrumBoardActions.LAUNCH_IPV6,
      launchIpv6
    ),
    takeEvery(
      scrumBoardActions.PULL_GIT,
      pullGit
    ),
    takeEvery(
      scrumBoardActions.START_VM_POLLER,
      watchVmPollerTask
    ),
    takeEvery(
      scrumBoardActions.START_IPV6_POLLER,
      watchV6PollerTask
    ),
    takeEvery(
      scrumBoardActions.START_GIT_POLLER,
      watchGitPollerTask
    ),
    takeEvery(
      scrumBoardActions.UPDATE_PROJECT, 
      updateProjectInfo,
    ),
    takeEvery(
      scrumBoardActions.LAUNCH_PROJECT, 
      launchProject,
    ),


    takeEvery(
      scrumBoardActions.LAUNCH_COMPOSE,
        launchCompose,
    ),
    takeEvery(
      scrumBoardActions.LAUNCH_COMPOSE_POLLER,
      launchComposePoller,
    ),
    takeEvery(
      scrumBoardActions.LAUNCH_DOCKER_POLLER,
      launchDockerPoller,
    ),
    takeEvery(
      scrumBoardActions.DELETE_PROJECT,
      deleteProject,
    ),
    takeEvery(
      scrumBoardActions.STOP_PROJECT, 
      stopProject,
    ),
    takeEvery(
      scrumBoardActions.STOP_COMPOSE,
      stopCompose,
    ),
    takeEvery(
      scrumBoardActions.STOP_COMPOSE_POLLER,
      stopComposePoller,
    ),
    takeEvery(
      scrumBoardActions.LAUNCH_VSCODE_PROJECT, 
      launchVSCodeProject,
    ),
    takeEvery(
      scrumBoardActions.LAUNCH_VSCODE,
        launchVSCode,
    ),
    takeEvery(
      scrumBoardActions.LAUNCH_VSCODE_POLLER,
      launchVSCodePoller,
    ),
    takeEvery(
      scrumBoardActions.LAUNCH_TTYD_POLLER,
      launchTtydPoller,
    ),
    takeEvery(
      scrumBoardActions.LAUNCH_LOGS_POLLER,
      launchLogsPoller,
    ),
    takeEvery(
      scrumBoardActions.LAUNCH_DOCKER_POLLER,
      launchDockerPoller,
    ),
    takeEvery(
      scrumBoardActions.LAUNCH_LOGS_TAILER,
      launchLogsTailer,
    ),
    takeEvery(
      scrumBoardActions.LAUNCH_PROXY_PROJECT, 
      launchProxyProject,
    ),
    takeEvery(
      scrumBoardActions.LAUNCH_PROXY,
        launchProxy,
    ),
    takeEvery(
      scrumBoardActions.LAUNCH_PROXY_POLLER,
      launchProxyPoller,
    ),
    takeEvery(scrumBoardActions.DELETE_BOARD_WATCHER, deleteBoardEffectSaga),
]);
}
