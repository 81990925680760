import defaultTheme from './default';
import customTheme from './custom';

const themes = {
  defaultTheme,
  customTheme,
};

export const themeConfig = {
  topbar: 'theme0',
  sidebar: 'theme6',
  layout: 'theme2',
  theme: 'defaultTheme',
};
export default themes;
