import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC1QraNtePLzSI65GBmzBVhVaBZtG4gSPw",
    authDomain: "codegr8s.firebaseapp.com",
    projectId: "codegr8s",
    storageBucket: "codegr8s.appspot.com",
    messagingSenderId: "767203529768",
    appId: "1:767203529768:web:3030f250146467820bd11d",
    measurementId: "G-QBP5D998BC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth
const auth = getAuth();

export { auth };