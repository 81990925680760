const scrumBoardActions = {
  //BOARD
  CLEAR_STATES: 'CLEAR_STATES',
  CREATE_OR_UPDATE_BOARD_WATCHER: 'CREATE_OR_UPDATE_BOARD_WATCHER',
  CREATE_OR_UPDATE_BOARD: 'CREATE_OR_UPDATE_BOARD',
  UPDATE_ID : 'UPDATE_ID',
  PREFLIGHT: 'PREFLIGHT',
  CREATE_PROJECT: 'CREATE_PROJECT',
  LAUNCH_VM: 'LAUNCH_VM',
  LAUNCH_IPV6: 'LAUNCH_IPV6',
  PULL_GIT: 'PULL_GIT',
  SET_ENV_LAUNCHED: 'SET_ENV_LAUNCHED',
  SET_STATUS_DETAILS: 'SET_STATUS_DETAILS',
  SET_TTYD_STATUS_DETAILS: 'SET_TTYD_STATUS_DETAILS',
  SET_DOCKER_STATUS_DETAILS: 'SET_DOCKER_STATUS_DETAILS',

  EDIT_BOARD: 'EDIT_BOARD',
  DELETE_BOARD_WATCHER: 'DELETE_BOARD_WATCHER',
  DELETE_BOARD: 'DELETE_BOARD',
  SELECT_BOARD: 'SELECT_BOARD',
  START_VM_POLLER: 'START_VM_POLLER',
  START_IPV6_POLLER: 'START_IPV6_POLLER',
  START_GIT_POLLER: 'START_GIT_POLLER',
  UPDATE_PROJECT: 'UPDATE_PROJECT',

  LAUNCH_PROJECT: 'LAUNCH_PROJECT',
  LAUNCH_COMPOSE: 'LAUNCH_COMPOSE',
  LAUNCH_UI: 'LAUNCH_UI',

  SET_VSCODE_STATUS_DETAILS: 'SET_VSCODE_STATUS_DETAILS',
  LAUNCH_VSCODE_PROJECT: 'LAUNCH_VSCODE_PROJECT',
  LAUNCH_VSCODE: 'LAUNCH_VSCODE',
  LAUNCH_VSCODE_POLLER: 'LAUNCH_VSCODE_POLLER',

  SET_PROJECT_STATUS_DETAILS: 'SET_PROJECT_STATUS_DETAILS',
  LAUNCH_COMPOSE_POLLER: 'START_COMPOSE_POLLER',
  LAUNCH_TTYD_POLLER: 'START_TTYD_POLLER',
  LAUNCH_LOGS_POLLER: 'START_LOGS_POLLER',
  LAUNCH_LOGS_TAILER: 'START_LOGS_TAILER',
  LAUNCH_UI_POLLER: 'START_UI_POLLER',
  LAUNCH_DOCKER_POLLER: 'START_DOCKER_POLLER',

  SET_PROXY_STATUS_DETAILS: 'SET_PROXY_STATUS_DETAILS',
  LAUNCH_PROXY_PROJECT: 'LAUNCH_PROXY_PROJECT',
  LAUNCH_PROXY: 'LAUNCH_PROXY',
  LAUNCH_PROXY_POLLER: 'LAUNCH_PROXY_POLLER',

  DELETE_PROJECT: 'DELETE_PROJECT',
  STOP_PROJECT: 'STOP_PROJECT',
  STOP_COMPOSE: 'STOP_COMPOSE',
  STOP_COMPOSE_POLLER: 'STOP_COMPOSE_POLLER',

  // SEARCH
  SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',

  // LOAD DATA
  LOAD_BOARDS_DATA_SAGA: 'LOAD_BOARDS_DATA_SAGA',
  SET_BOARDS_DATA: 'SET_BOARDS_DATA',

  // Load Data Actions
  boardsRenderWatcher: () => ({
    type: scrumBoardActions.LOAD_BOARDS_DATA_SAGA,
  }),

  statusDetailsRenderWatcher: id => {
    return {
      type: scrumBoardActions.LOAD_DOCKER_STATUS,
      payload: id,
    }
  },

  setBoardsData: boards => ({
    type: scrumBoardActions.SET_BOARDS_DATA,
    payload: boards,
  }),
  
  setSearchText: payload => {
    return {
      type: scrumBoardActions.SET_SEARCH_TEXT,
      payload,
    };
  },

  // Board Actions
  clearStates: id => {
    return {
      type: scrumBoardActions.CLEAR_STATES,
      payload: id,
    };
  },
  createOrUpdateBoardWatcher: board => {
    return {
      type: scrumBoardActions.CREATE_OR_UPDATE_BOARD_WATCHER,
      payload: board,
    };
  },
  updateId: project_info => {
    return {
      type: scrumBoardActions.UPDATE_ID,
      payload: project_info,
    };
  },
  projectPreflight: project_info => {
    return {
      type: scrumBoardActions.PREFLIGHT,
      payload: project_info,
    };
  },
  createProject: project_info => {
    return {
      type: scrumBoardActions.CREATE_PROJECT,
      payload: project_info,
    }
  },
  deleteProject: id => {
    return {
      type: scrumBoardActions.DELETE_PROJECT,
      payload: id,
    }
  },
  launchVM: project_info => {
    return {
      type: scrumBoardActions.LAUNCH_VM,
      payload: project_info,
    }
  },
  launchIpv6: project_info => {
    return {
      type: scrumBoardActions.LAUNCH_IPV6,
      payload: project_info,
    }
  },
  pullGit: project_info => {
    return {
      type: scrumBoardActions.PULL_GIT,
      payload: project_info,
    }
  },
  startVMPoller: id => {
    return {
      type: scrumBoardActions.START_VM_POLLER,
      payload: id,
    }
  },
  startV6Poller: id => {
    return {
      type: scrumBoardActions.START_IPV6_POLLER,
      payload: id,
    }
  },
  startGitPoller: id => {
    return {
      type: scrumBoardActions.START_GIT_POLLER,
      payload: id,
    }
  },
  createOrUpdateBoard: board => {
    return {
      type: scrumBoardActions.CREATE_OR_UPDATE_BOARD,
      payload: board,
    };
  },
  editBoard: board => {
    return {
      type: scrumBoardActions.EDIT_BOARD,
      payload: board,
    };
  },
  setEnvLaunched: envLaunched => {
    return {
      type: scrumBoardActions.SET_ENV_LAUNCHED,
      payload: envLaunched,
    };
  },
  setStatusDetails: statusDetails => {
    return {
      type: scrumBoardActions.SET_STATUS_DETAILS,
      payload: statusDetails,
    };
  },
  setTtydStatusDetails: ttydStatusDetails => {
    return {
      type: scrumBoardActions.SET_TTYD_STATUS_DETAILS,
      payload: ttydStatusDetails,
    };
  },
  setDockerStatusDetails: dockerStatusDetails => {
    return {
      type: scrumBoardActions.SET_DOCKER_STATUS_DETAILS,
      payload: dockerStatusDetails,
    };
  },
  updateProject: board => {
    return {
      type: scrumBoardActions.UPDATE_PROJECT,
      payload: board,
    };
  },
  launchProject: board => {
    return {
      type: scrumBoardActions.LAUNCH_PROJECT,
      payload: board,
    }
  },

  launchCompose: board => {
    return {
      type: scrumBoardActions.LAUNCH_COMPOSE,
      payload: board,
    }
  },
  launchUI: board => {
    return {
      type: scrumBoardActions.LAUNCH_UI,
      payload: board,
    }
  },

  setVSCodeStatusDetails: vsCodeStatusDetails => {
    return {
        type: scrumBoardActions.SET_VSCODE_STATUS_DETAILS,
        payload: vsCodeStatusDetails,
    };
}, 
  launchVSCodeProject: board => {
    return {
      type: scrumBoardActions.LAUNCH_VSCODE_PROJECT,
      payload: board,
    }
  },
  launchVSCode: board => {
      return {
        type: scrumBoardActions.LAUNCH_VSCODE,
        payload: board,
      }
  },
  launchVSCodePoller: board => {
    return {
      type: scrumBoardActions.LAUNCH_VSCODE_POLLER,
      payload: board,
    }
  },

  setProxyStatusDetails: proxyStatusDetails => {
    return {
        type: scrumBoardActions.SET_PROXY_STATUS_DETAILS,
        payload: proxyStatusDetails,
    };
  }, 
  launchProxyProject: board => {
    return {
      type: scrumBoardActions.LAUNCH_PROXY_PROJECT,
      payload: board,
    }
  },
  launchProxy: board => {
      return {
        type: scrumBoardActions.LAUNCH_PROXY,
        payload: board,
      }
  },
  launchProxyPoller: board => {
    return {
      type: scrumBoardActions.LAUNCH_PROXY_POLLER,
      payload: board,
    }
  },

  setProjectStatusDetails: projectStatusDetails => {
      return {
          type: scrumBoardActions.SET_PROJECT_STATUS_DETAILS,
          payload: projectStatusDetails,
      };
  },  
  launchComposePoller: board => {
    return {
      type: scrumBoardActions.LAUNCH_COMPOSE_POLLER,
      payload: board,
    }
  },
  launchDockerPoller: board => {
    return {
      type: scrumBoardActions.LAUNCH_DOCKER_POLLER,
      payload: board,
    }
  },
  launchLogsPoller: board => {
      return {
        type: scrumBoardActions.LAUNCH_LOGS_POLLER,
        payload: board,
      }
  },
  launchLogsTailer: board => {
    return {
      type: scrumBoardActions.LAUNCH_LOGS_TAILER,
      payload: board,
    }
  },
  launchTtydPoller: portInfo => {
      return {
        type: scrumBoardActions.LAUNCH_TTYD_POLLER,
        payload: portInfo,
      }
  },
  launchUIPoller: id => {
      return {
        type: scrumBoardActions.LAUNCH_UI_POLLER,
        payload: id,
      }
  },
  stopProject: board => {
    return {
      type: scrumBoardActions.STOP_PROJECT,
      payload: board,
    }
  },
  stopCompose: board => {
    return {
      type: scrumBoardActions.STOP_COMPOSE,
      payload: board,
    }
  },
  stopComposePoller: board => {
    return {
      type: scrumBoardActions.STOP_COMPOSE_POLLER,
      payload: board,
    }
  },
  deleteBoardWatcher: boardID => {
    return {
      type: scrumBoardActions.DELETE_BOARD_WATCHER,
      payload: boardID,
    };
  },
  deleteBoard: boardID => {
    return {
      type: scrumBoardActions.DELETE_BOARD,
      payload: boardID,
    };
  },
};

export default scrumBoardActions;
